import helper from './helper'

const API_URL = 'https://test.minboo.net/flask/v1';

export default {
    login(data) {
        return axios.post(`${API_URL}/login`, helper.formData(data))
            .then(response => response.data)
    },

    logout() {
        return axios.post(`${API_URL}/logout`)
            .then(response => response.data)
    },

    getAreas() {
        return axios.get(`${API_URL}/areas`)
            .then(response => response.data)
    },

    getPorts() {
        return axios.get(`${API_URL}/ports`)
            .then(response => response.data)
    },

    getRoutes() {
        return axios.get(`${API_URL}/routes`)
            .then(response => response.data)
    },

    getShips() {
        return axios.get(`${API_URL}/ships`)
            .then(response => response.data)
    },

    getCompanies() {
        return axios.get(`${API_URL}/companies`)
            .then(response => response.data)
    },

    getCargoBulletinBoard(params) {
        return axios.get(`${API_URL}/cargo_bulletin_board`, { params })
            .then(response => {
                const data = response.data.sort((a, b) => {
                    if (a.l1_port_date > b.l1_port_date) {
                        return 1
                    } else if (a.l1_port_date < b.l1_port_date) {
                        return -1
                    }
                    if (a.l1_port_id > b.l1_port_id) {
                        return 1
                    } else if (a.l1_port_id < b.l1_port_id) {
                        return -1
                    }
                    return 0
                })
                return data
            })
    },

    getShipBulletinBoard(params) {
        return axios.get(`${API_URL}/ship_bulletin_board`, { params })
            .then(response => {
                const data = response.data.sort((a, b) => {
                    if (a.free_date > b.free_date) {
                        return 1
                    } else if (a.free_date < b.free_date) {
                        return -1
                    }
                    if (a.free_port_id > b.free_port_id) {
                        return 1
                    } else if (a.free_port_id < b.free_port_id) {
                        return -1
                    }
                    return 0
                })
                return data
            })
    },

    postCargoBulletinBoard(data) {
        // return axios.post(`${API_URL}/cargo_bulletin_board`, helper.formData(data))
        //     .then(response => response.data)
        return axios({
            method: 'post',
            url: `${API_URL}/cargo_bulletin_board`,
            data: data
        }).then(response => response.data)
    },

    postShipBulletinBoard(data) {
        return axios({
            method: 'post',
            url: `${API_URL}/ship_bulletin_board`,
            data: data
        }).then(response => response.data)
    },

    auth() {
        return axios.post(`${API_URL}/auth`)
            .then(response => response.data)
    },
}
